<template>
	<div class="page">
		<div class="title">
			<span>企业基础认证</span>
			<span>切换到个人认证
				<i class="el-icon-caret-right"></i>
			</span>
		</div>
		<div class="content">
			<div class="content-title">{{steps == 1 ? '企业信息' : '法人信息' }}</div>
			<!-- 表单 -->
			<div class="form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="top">
					<template v-if="steps == 1">
						<el-form-item label="对公结算证明照">
							<el-upload :auto-upload="true" class="avatar-uploader" action="" multiple
								:before-upload="dgclUpFile">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="商户类型">
							<el-radio border v-model="ruleForm.regMerType" label="00" @change="ruleForm.bankAcctType='1'">企业商户</el-radio>
							<el-radio border v-model="ruleForm.regMerType" label="01">个人商户</el-radio>
						</el-form-item>
						<el-form-item label="银行卡正面张" v-if="ruleForm.regMerType == '01'">
							<el-upload :auto-upload="true" class="avatar-uploader" action="" multiple
								:before-upload="bankFUpFile">
								<img v-if="bankFimageUrl" :src="bankFimageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="自拍照(手持身份证)" v-if="ruleForm.regMerType == '01'">
							<el-upload :auto-upload="true" class="avatar-uploader" action="" multiple
								:before-upload="idCFUpFile">
								<img v-if="idCFimageUrl" :src="idCFimageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="开户行账号类型" v-if="ruleForm.regMerType == '01'">
							<el-radio border v-model="ruleForm.bankAcctType" label="0">个人账户</el-radio>
							<el-radio border v-model="ruleForm.bankAcctType" label="1">公司账户</el-radio>
						</el-form-item>
						<el-form-item label="开户账户名称">
							<el-input v-model="ruleForm.bankAcctName" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item label="开户行账号">
							<el-input v-model="ruleForm.bankAcctNo" @blur="bankInput" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item label="所属银行">
							<el-input v-model="ruleForm.bankBranchName" placeholder="请输入"></el-input>
							<!-- <el-button type="primary">搜索</el-button> -->
						</el-form-item>
						<el-form-item label="选择开户银行地区">
							<el-cascader placeholder="可搜索" :options="options" v-model="bankAreaCode" filterable>
							</el-cascader>
						</el-form-item>
						<el-form-item label="支行名称搜索(输入关键字即可,请在下一项选择银行)">
							<el-input v-model="bankAreaName" placeholder="请输入" style="width: 300px;"></el-input>
							<el-button type="primary" @click="getBranchBankList">搜索</el-button>
						</el-form-item>
						<el-form-item label="选择开户银行(根据银行地区及支行名称搜索)">
							<el-cascader placeholder="可搜索" :options="bankOptions" @change="bankNofun" :props="{
								  value: 'code',
								  label: 'bankBranchName'
							  }" filterable></el-cascader>
						</el-form-item>
						<!-- <el-form-item label="对公账户认证">
						  <el-button type="primary">开始认证</el-button>
						</el-form-item>
						<el-form-item label="开通数字人民币">
						  <el-switch v-model="value" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
						</el-form-item> -->
					</template>


					<template v-if="steps == 2">
						<el-form-item label="控股股东">
							<el-input v-model="ruleForm.complexSupplement.shareholderName" style="width: 300px;" placeholder="请输入"></el-input>
							<el-button type="primary" @click="getIdentifyInfo">同法人信息</el-button>
						</el-form-item>
						<el-form-item label="证件类型">
							<el-select v-model="ruleForm.complexSupplement.shareholderCertType" placeholder="请选择">
								<el-option :label="item" :value="index" v-for="(item,index) in certificateType"
									:key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="证件号码">
							<el-input v-model="ruleForm.complexSupplement.shareholderCertno" placeholder="请输入">
							</el-input>
						</el-form-item>
						<el-form-item label="身份证有效期">
							<el-date-picker :disabled="compChecked" :value-format="'yyyy-MM-dd'"
								v-model="ruleForm.complexSupplement.shareholderCertExpire" type="date"
								placeholder="选择日期">
							</el-date-picker>
							<el-checkbox v-model="compChecked" @click="certExpire">长期</el-checkbox>
						</el-form-item>
						<el-form-item label="家庭地址">
							<el-input v-model="ruleForm.complexSupplement.shareholderHomeAddr" placeholder="请输入">
							</el-input>
						</el-form-item>
						<div v-for="(item,index) in bnfList" :key="index">
							<el-form-item :label="'受益人' + (index+1)">
								<el-input v-model="item.bnfName" placeholder="请输入"></el-input>
								<!-- <el-button type="primary">同控股股东</el-button> -->
							</el-form-item>
							<el-form-item label="证件类型">
								<el-select v-model="item.bnfCertType" placeholder="请选择">
									<el-option :label="items" :value="index" v-for="(items,index) in certificateType"
										:key="index"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="证件号码">
								<el-input v-model="item.bnfCertno" placeholder="请输入"></el-input>
							</el-form-item>
							<el-form-item label="身份证有效期">
								<el-date-picker :disabled="bnfChecked" :value-format="'yyyy-MM-dd'"
									v-model="item.bnfCertExpire" type="date" placeholder="选择日期">
								</el-date-picker>
								<el-checkbox v-model="bnfChecked">长期</el-checkbox>
							</el-form-item>
							<el-form-item label="家庭地址">
								<el-input v-model="item.bnfHomeAddr" placeholder="请输入"></el-input>
							</el-form-item>
						</div>
						<el-form-item>
							<el-button type="primary" @click="addBnf">+添加受益人</el-button>
							<el-button type="danger" v-if="bnfList.length > 0" @click="delBnf">-删除受益人</el-button>
						</el-form-item>
					</template>


					<template v-if="steps == 3">
						<el-form-item label="公司门面照" prop="region">
							<el-upload :auto-upload="true" class="avatar-uploader" action="" multiple
								:before-upload="dmfUpFile">
								<img v-if="dpZimageUrl" :src="dpZimageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<!-- <el-upload class="upload-demo" drag action="" multiple :before-upload="dmfUpFile">
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">
									将文件拖到此处，或<em style="color: #fbbd5e">点击上传</em>
								</div>
							</el-upload> -->
						</el-form-item>
						<el-form-item label="公司室内照" prop="region">
							<el-upload :auto-upload="true" class="avatar-uploader" action="" multiple
								:before-upload="dmbUpFile">
								<img v-if="dpBimageUrl" :src="dpBimageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<!-- <el-upload class="upload-demo" drag action="" multiple :before-upload="dmbUpFile">
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">
									将文件拖到此处，或<em style="color: #fbbd5e">点击上传</em>
								</div>
							</el-upload> -->
						</el-form-item>
						<el-form-item label="辅助证明材料(选传)" prop="region">
							<el-upload :auto-upload="true" class="avatar-uploader" action="" multiple
								:before-upload="qtUpFile">
								<img v-if="qtimageUrl" :src="qtimageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<!-- <el-upload class="upload-demo" drag action="" multiple :before-upload="qtUpFile">
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">
									将文件拖到此处，或<em style="color: #fbbd5e">点击上传</em>
								</div>
							</el-upload> -->
						</el-form-item>
					</template>
					<el-form-item>
						<el-button @click="resetForm('ruleForm')">保存草稿</el-button>
						<el-button @click="steps -= 1" v-if="steps != 1">上一步</el-button>
						<el-button type="primary" @click="submitForm('ruleForm')" v-if="steps == 1 || steps == 2">下一步
						</el-button>
						<el-button type="primary" @click="postForm" v-if="steps == 3">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="contentAfter">
				<div>
					<el-steps direction="vertical" :active="steps">
						<el-step title="企业信息" description="(对应个人认证的实名认证)"></el-step>
						<el-step title="法人信息" description="(对应个人认证的基础信息完善)"></el-step>
					</el-steps>
				</div>
			</div>
		</div>

		<div class="footer">

		</div>
		<!-- 回到顶部 -->
		<el-backtop></el-backtop>
		<!-- 弹窗 -->
		<el-dialog title="开户账号校验" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :show-close='false'>
			<el-form :model="bankAcctForm">
				<el-form-item label="账户余额(分)" :label-width="'100px'">
					<el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="bankAcctForm.money" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<p>*我们将向您开户账户汇款，请输入汇款后的开户账号金额，我们将提交给银联进行核对。</p>
			<div slot="footer" class="dialog-footer">
				<!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
				<el-button type="primary" @click="companyAccountVerify">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	let BIN = require('bankcardinfo');
	import BMF from "browser-md5-file";
	import {
		lotusAddressJson
	} from "./bankListData.js"
	export default {
		data() {
			return {
				dialogFormVisible: false,
				bankAcctForm: {
					money: ''
				},
				uploading: false,
				ruleForm: {
					bankNo: '',
					bankAcctName: '',
					bankAcctNo: '',
					bankAcctType: '1',
					legalMobile: '',
					bankBranchName: '',
					regMerType: '00', // 企业： 00  个体： 01
					mccCode: '7399',
					complexSupplement: {
						shareholderName: '',
						shareholderCertType: '',
						shareholderCertno: '',
						shareholderCertExpire: '',
						shareholderHomeAddr: '',
					},
				},
				bnfList: [],
				upActoin: '/api/file/applet/upload',
				upHeaders: {
					Authorization: 'Bearer ' + window.localStorage.getItem("token"),
				},
				certificateType: {
					1: '身份证',
					2: '护照',
					3: '军官证',
					4: '警官证',
					5: '士兵证',
					6: '台湾居民来往大陆通行证',
					7: '回乡证',
					8: '港澳居民来往内地通行证',
					10: '港澳台居民居住证',
					11: '营业执照',
					12: '组织机构代码证',
					13: '税务登记证',
					14: '商业登记证',
					15: '民办非企业登记证书',
					16: '批文证明'
				},
				steps: 1,
				imgData: {},
				imageUrl: '',
				dpZimageUrl: '',
				dpBimageUrl: '',
				qtimageUrl: '',
				bankFimageUrl: '',
				idCFimageUrl: '',
				compChecked: false,
				bnfChecked: false,
				bankList: [],
				options: lotusAddressJson,
				bankOptions: [],
				bankAreaCode: '',
				bankAreaName: '',
				rules: {
					// 第一页
					bankAcctName: [{
						required: true,
						message: '请填写开户账户名称',
						trigger: 'blur'
					}],
					bankAcctNo: [{
						required: true,
						message: '请填写开户行账号',
						trigger: 'blur'
					}, ],
					bankAcctType: [{
						required: true,
						message: '请选择开户行账号类型',
						trigger: 'blur'
					}],
					bankBranchName: [{
						required: true,
						message: '请填写所属银行',
						trigger: 'blur'
					}],
					bankOptions: [{
						required: true,
						message: '请选择开户银行',
						trigger: 'blur'
					}],
					// 第二页
					shareholderName: [{
						required: true,
						message: '请填写控股股东',
						trigger: 'blur'
					}],
					shareholderCertType: [{
						required: true,
						message: '请填写证件类型',
						trigger: 'blur'
					}],
					shareholderCertno: [{
						required: true,
						message: '请填写证件号码',
						trigger: 'blur'
					}],
					shareholderCertExpire: [{
						required: true,
						message: '请选择身份证有效期',
						trigger: 'blur'
					}],
					shareholderHomeAddr: [{
						required: true,
						message: '请填写家庭地址',
						trigger: 'blur'
					}],
					legalName: [{
						required: true,
						message: '请填写法人姓名',
						trigger: 'blur'
					}],
					idCardNo: [{
						required: true,
						message: '请填写法人身份证号',
						trigger: 'blur'
					}],
					effectiveDate: [{
						required: true,
						message: '请选择身份证有效期',
						trigger: 'blur'
					}],
					legalAddress: [{
						required: true,
						message: '请填写联系地址',
						trigger: 'blur'
					}],
					legalPhone: [{
						required: true,
						message: '请填写法人手机号',
						trigger: 'blur'
					}],
					legalEmail: [{
						required: true,
						message: '请填写法人邮箱',
						trigger: 'blur'
					}],
				},
			}
		},
		computed: {

		},
		mounted() {
			this.getIdentifyInfoO()
		},
		methods: {
			getIdentifyInfoO() {
				this.$http.getContractResult().then((res) => {
					  if (res.code == 0 && res.data) {
						  if(!res.data.identify){
							this.$alert('您未完成实名，请先前往实名认证', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									this.$router.replace("/identity");
								}
							});
						  } else if(!res.data.userShop) {
							this.$alert('您未完成店铺注册，请先前往开通店铺', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									this.$router.replace("/agencyRegister/createShop");
								}
							});
						  } else {
							this.getComplexUpload()
						  }
					  }
				});
			},
			// 获取法人信息
			getIdentifyInfo() {
			  this.$http.getIdentifyInfo({ detail: false }).then((res) => {
				if (res.code == 0 && res.data) {
				  this.ruleForm.complexSupplement.shareholderCertType = '1';
				  this.ruleForm.complexSupplement.shareholderCertno = res.data.idCardNo;
				  this.ruleForm.complexSupplement.shareholderName = res.data.legalName;
				}
			  });
			},
			// 弹窗
			qianyueModule() {
				this.$alert('请在弹出的新窗口完成签约，签约结果预计在十分钟内生效，点击下方按钮返回支付认证刷新签约状态', '签约提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.replace("/agencyRegister/createShop");
					}
				});
			},
			// 获取审核状态
			getComplexUpload() {
				this.$http.getComplexUpload().then(res => {
					if (res.code == 0) {
						if ('verifyStatus' in res.data && res.data.verifyStatus == 0) {
							// 企业账户状态下未校验金额 弹出校验窗口
							this.dialogFormVisible = true
						} else if (res.data.signUrl) {
							// 未签字
							this.qianyueModule()
							window.open(res.data.signUrl, '_blank')
						}
					}
				})
			},
			certExpire() {
				if (this.compChecked) {
					this.ruleForm.complexSupplement.shareholderCertExpire = '9999-12-31'
				}
				if(this.bnfList.length>1) {
					for (const i in this.bnfList) {
						if (this.bnfList[i].bnfChecked) {
							this.bnfList[i].bnfCertExpire = '9999-12-31'
						}
						delete this.bnfList[i].bnfChecked
					}
				}
			},
			// datachuli() {
			// 	let data = lotusAddressJson
			// 	let rdata = addressJson
			// 	// let s = data.map((v,i) => {
			// 	// 	if(v.hasOwnProperty('parent')) {
			// 	// 		for(let o = 0; o<rdata.length; o++) {
			// 	// 			if(rdata[o].value == v.parent) {
			// 	// 				rdata[o].children.push(v)
			// 	// 			}
			// 	// 		}
			// 	// 	} else {
			// 	// 		v.children = []
			// 	// 		rdata = [...rdata, v]
			// 	// 	}
			// 	// })
			// 	data.map((item, index) => {
			// 		item.children.map((v, i) => {
			// 			v.children = []
			// 			rdata.map((s, b) => {
			// 				if(s.hasOwnProperty('parent') && v.value == s.parent)  {
			// 					v.children = [...v.children, s]
			// 				}
			// 			})
			// 		})
			// 	})
			// 	console.log(data)
			// },

			bankNofun(e) {
				this.ruleForm.bankNo = e[0]
			},
			// 查询银行支行
			getBranchBankList() {
				if(!this.bankAreaCode[1]) {
					this.$message.error('请先选择地区');
					return
				}
				const loading = this.$loading({
				  lock: true,
				  text: '正在查询银行，请稍等',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.9)'
				});
				this.$http.getBranchBankList({
					areaCode: this.bankAreaCode[1].slice(0, 4),
					key: this.bankAreaName,
				}).then(res => {
					if (res.code == 0) {
						this.bankOptions = res.data.branchBankList
						console.log(this.bankOptions)
					}
				}).finally(d => {
					setTimeout(() => {
					  loading.close();
					}, 100);
				})
			},
			// 获取银行列表
			getBankCode() {
				this.$http.getBankCode().then(res => {
					this.bankList = res.data
				})
			},
			// 对公材料上传
			qtUpFile(file) {
				this.beforeUpload(file, '0099')
			},
			// 对公材料上传
			dmbUpFile(file) {
				this.beforeUpload(file, '0015')
			},
			// 对公材料上传
			dmfUpFile(file) {
				this.beforeUpload(file, '0005')
			},
			// 对公材料上传
			dgclUpFile(file) {
				this.beforeUpload(file, '0006')
			},
			// 银行卡正面张
			bankFUpFile(file) {
				this.beforeUpload(file, '0025')
			},
			// 自拍照(手持身份证)
			idCFUpFile(file) {
				this.beforeUpload(file, '0007')
			},
			//上传
			async beforeUpload(file, v) {
				let md5 = await this.transportFile(file);
				console.log(v);
				const formData = {
					attachmentObjectTypeCode: "chinaumsFiles",
					fileSize: file.size,
					md5: md5,
					originalFilename: file.name,
					isEncryption: true,
				};
				this.uploading = true;
				const loading = this.$loading({
				  lock: true,
				  text: '上传图片中',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.9)'
				});
				this.$http.Upload(formData)
					.then((res) => {
						console.log(res);
						let url = res.data.presignedUploadUrl;
						let md5Base64 = res.data.md5Base64;
						// this.fileList1[this.fileList1.length - 1].status = 'uploading'
						// this.fileList1[this.fileList1.length - 1].message = '上传中...'
						if (res.code == 0) {
							this.$http.uploadToServer(url, md5Base64, file, res.data.mimeType)
								.then(
									(resp) => {
										this.$http.chinaumsPicUpload({
											url: res.data.fileUrl,
											fileSize: file.size,
											picBase64: md5Base64,
											picType: v,
											compress: true,
										}).then(imgres => {
											if (imgres.code == 0) {
												this.$notify({
													title: "提示",
													message: "上传成功",
												});
												setTimeout(() => {
												  loading.close();
												}, 100);
												switch (v) {
													case '0006':
														this.imageUrl = res.data.fileUrl
														break;
													case '0005':
														this.dpZimageUrl = res.data.fileUrl
														break;
													case '0015':
														this.dpBimageUrl = res.data.fileUrl
														break;
													case '0099':
														this.qtimageUrl = res.data.fileUrl
														break;
													case '0025':
														this.bankFimageUrl = res.data.fileUrl
														break;
													case '0007':
														this.idCFimageUrl = res.data.fileUrl
														break;
													default:
														break;
												}
											}
										})
									}
								);
						} else {
							this.uploading = false;
							this.$notify({
								title: "提示",
								message: "上传失败",
							});
							setTimeout(() => {
							  loading.close();
							}, 100);
						}
					});
				return false;
			},
			transportFile(file) {
				const bmf = new BMF();
				return new Promise((resolve, reject) => {
					bmf.md5(file, (err, md5) => {
						resolve(md5);
					});
				})
			},
			// 添加受益人
			addBnf() {
				this.bnfList.push({
					bnfCertExpire: '',
					bnfCertType: '',
					bnfCertno: '',
					bnfHomeAddr: '',
					bnfName: '',
					bnfChecked: false,
				})
			},
			// 删除受益人
			delBnf() {
				this.bnfList.pop()
			},
			bankInput() {
				if (!this.ruleForm.bankAcctNo) return
				// 根据银行卡号识别 银行（这是异步的）
				this.ruleForm.bankAcctNo = this.ruleForm.bankAcctNo.replace(/\s+/g, '')
				BIN.getBankBin(this.ruleForm.bankAcctNo)
					.then(res => {
						console.log(res)
						this.ruleForm.bankBranchName = res.bankName

					})
			},
			// 上传前
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			submitForm(formName) {
				if (this.steps) {
					this.certExpire()
				}
				if (!this.jiaoyan()) return
				this.$http.chinaumsComplexUpload(this.ruleForm).then(res => {})
				this.steps += 1
				// this.steps += 1
			},
			// 最终提交
			postForm() {
				this.bnfListJy()
				this.$http.chinaumsComplexUpload(this.ruleForm).then(res => {
					this.$http.summaryComplexForEnterprise().then(res => {
						this.$http.complexUpload().then(res => {
							// 个人商户和企业商户走不同
							if (res.code == 0) {
								if(this.ruleForm.bankAcctType == '0') {
									// 个人账户
									this.qianyueModule()
									window.open(res.data, '_blank')
								} else if(this.ruleForm.bankAcctType == '1'){
									this.$http.requestAccountVerify().then(res => {
										if(res.code == 0) {
											this.dialogFormVisible = true
										}
									})
								}
							}
						});
					});
				})
			},
			// 对公账户发起验证
			companyAccountVerify() {
				if(this.bankAcctForm.money<0) {
					this.$message.error('请输入正确的金额');
					return
				}
				this.$http.companyAccountVerify(this.bankAcctForm).then(res => {
					if(res.code == 0) {
						this.qianyueModule()
						window.open(res.data, '_blank')
					}
				})
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// personForm.legalCardDeadline = '9999-12-31' 0006
			bnfListJy() {
				let bnfInfo = this.bnfList[0]
				for (const i in bnfInfo) {
					if (bnfInfo[i]) {
						this.ruleForm.bnfList = this.bnfList
						return
					}
				}
				if (this.ruleForm.bnfList) {
					delete this.ruleForm.bnfList
				}
			},
			jiaoyan() {
				let jiaoyandata
				switch (this.steps) {
					case 1:
						const {
							bankAcctName, bankAcctNo, bankAcctType, bankBranchName, bankNo
						} = this.ruleForm
						jiaoyandata = {
							bankAcctName,
							bankAcctNo,
							bankAcctType,
							bankBranchName,
							bankNo
						}
						break;
					case 2:
						const {
							shareholderName, shareholderCertType, shareholderCertno, shareholderCertExpire,
							shareholderHomeAddr
						} = this.ruleForm.complexSupplement
						jiaoyandata = {
							shareholderName,
							shareholderCertType,
							shareholderCertno,
							shareholderCertExpire,
							shareholderHomeAddr
						}
						break;
					default:
						break;
				}
				let msgArr = {
					bankAcctName: '请输入 开户账户名称',
					bankAcctNo: '请输入 开户行账号',
					bankAcctType: '请选择 开户行账号类型',
					bankBranchName: '请输入 所属银行',
					bankNo: '请选择 开户银行',
					shareholderName: '请输入 控股股东',
					shareholderCertType: '请选择 控股股东证件类型',
					shareholderCertno: '请输入 控股股东证件号码',
					shareholderCertExpire: '请选择 控股股东身份证有效期',
					shareholderHomeAddr: '请输入 控股股东家庭地址',
				}
				for (const i in jiaoyandata) {
					if (!jiaoyandata[i]) {
						this.$message.error(msgArr[i]);
						return false
					}
				}
				return true
			}
		}
	};
</script>
<style lang="scss" scoped>
	div.page {
		width: 100vw;
		min-height: 100vh;
		background-color: #fff;
		padding: 20px 0;

		div.title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 40vw;
			min-width: 500px;
			margin: auto;
			padding: 20px 0;

			&>span:first-of-type {
				font-size: 18px;
				color: #303133;
			}

			span {
				font-size: 16px;
				color: #606266;
			}
		}

		div.content {
			width: 40vw;
			min-width: 500px;
			min-height: 100vh;
			margin: auto;
			// padding: 20px;
			background: #ffffff;
			border: 1px solid #dfe2e7;
			position: relative;

			div.content-title {
				padding: 20px;
				font-size: 16px;
				text-align: center;
				background-color: #f5f6f8;
				border-bottom: 1px solid #dfe2e7;
			}

			div.form {
				padding: 20px;
				background: #ffffff;
			}

			form.el-form {
				padding: 20px;
				background: #f3f6f9;
			}

			div.contentAfter {
				background-color: #ffffff;
				border: 1px solid #dfe2e7;
				width: 250px;
				position: absolute;
				top: 0;
				right: -310px;
				padding: 20px;
			}
		}

		.el-form-item__content {
			display: flex;

			&>div {
				flex: 1;
			}
		}

		.el-step__main {
			margin-bottom: 10px;
		}

		.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409eff;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 178px;
			height: 178px;
			line-height: 178px;
			text-align: center;
		}

		.avatar {
			width: 178px;
			height: 178px;
			display: block;
		}
	}
</style>
